var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"about"},[_c('h1',[_vm._v("This is an about page")]),_c('pre',[_vm._v(_vm._s(Object.assign(
      {},
      ...this.$route.hash
        .replace("#", "")
        .split("&")
        .map((x) => ({ [x.split("=")[0]]: x.split("=")[1] }))
    )["access_token"]))]),_c('pre',{on:{"load":_vm.store}},[_vm._v(_vm._s(Object.assign(
      {},
      ...this.$route.hash
        .replace("#", "")
        .split("&")
        .map((x) => ({ [x.split("=")[0]]: x.split("=")[1] }))
    )))])])
}
var staticRenderFns = []

export { render, staticRenderFns }