
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  mounted() {
    let jwt = Object.assign(
      {},
      ...this.$route.hash
        .replace("#", "")
        .split("&")
        .map((x) => ({ [x.split("=")[0]]: x.split("=")[1] }))
    );
    this.$store.dispatch("oktaCallback", { jwt });
  },
  computed: {},
  data: () => ({}),
  methods: {},
})
export default class Home extends Vue {}
